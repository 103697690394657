import React from "react"
import Wrapper from "../components/Wrapper"
import Layout from "../components/Layout"

const App = () => { 
  console.log(`App`)
  
  return (
  <Wrapper>
    <Layout>
    </Layout>
  </Wrapper>
)
}

export default App